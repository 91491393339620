<template>
<Fragment>
  <admin-documentos-component
      v-if="detail_etapa_var?.id"
      :etapa="detail_etapa_var"
      :permisos="{ leer: true, escribir: proceso?.id_estado_proceso!==5 && proceso?.id_estado_proceso!==9}"
      show-form
      class="mt-8"
      :extra-params="{ id_institucion: selectedUnidad?.instituciones?.id }"
      @refresh="FetchEtapaFtn"
  />
  <h6 class="text-h6 text-center secondary--text" v-else>No se ha encontrado una etapa para los documentos de solicitud de oferta/propuesta</h6>
</Fragment>
</template>

<script>
import { mapActions, mapMutations, mapState } from "vuex";
import AdminDocumentosComponent from "./Etapas/AdminDocumentosComponent.vue";

export default {
  name: "ContainerAdminComponent",

  components: {
    AdminDocumentosComponent,
  },
  props: {
    proceso: {
      type: Object,
      required: false,
    },
  },

  data: () => ({
    detail_etapa_var: {},
  }),

  computed: {
    ...mapState(["anioFiscal", "selectedUnidad"]),
  },

  methods: {
    ...mapActions("procesoCompraDoc", ["getDocumentosProcesoPorTipo"]),
    ...mapMutations("procesoCompraDoc", ["setExistSolicitud"]),
    async FetchEtapaFtn() {

      const { data, status } = await this.services.PacProcesos.getEtapaSolicitudes(this.$route.params.idProceso, 21);


      if (status === 200 && data[0] !== null) {
        this.detail_etapa_var = data[0];
        await this.FetchDocsEtapa(
          this.$route.params.idProceso,
          this.detail_etapa_var.id_tipo_documento
        );
      } else {
        this.setExistSolicitud(true);
      }
    },

    async FetchDocsEtapa(id_proceso_param, id_tipo_doc_param) {
      const response = await this.getDocumentosProcesoPorTipo({
        id_proceso_compra: id_proceso_param,
        id_tipo_documento: id_tipo_doc_param,
      });

      this.setExistSolicitud(response.length > 0);
    },
  },

  async created() {
    await this.FetchEtapaFtn();
  },
};
</script>